<template>
  <div class="about">
<!--    <swiper :options="swiperOption" class="swiperBlock flexItem110">-->
<!--      <swiper-item class="flexColStartRowCenter" v-for="(item,index) in banner" :key="index">-->
<!--        <img :src="item.img" @click="bannerClick(item,index)">-->
<!--      </swiper-item>-->
<!--    </swiper>-->
    <image-box :src="require('../../assets/image/about/about_1.jpg?v=1')" :height="1408"></image-box>
    <image-box :src="require('../../assets/image/about/about_2.jpg?v=1')" :height="1170"></image-box>
    <image-box :src="require('../../assets/image/about/about_3.jpg?v=1')" :height="1014"></image-box>
    <image-box :src="require('../../assets/image/about/about_4.jpg?v=1')" :height="1950"></image-box>
    <image-box :src="require('../../assets/image/about/about_5.jpg?v=1')" :height="1010"></image-box>
    <image-box :src="require('../../assets/image/about/about_6.jpg?v=1')" :height="1344"></image-box>
    <image-box :src="require('../../assets/image/about/about_7.jpg?v=1')" :height="500">
      <a class="about-7-button" href="http://farbeyondrecovery.mikecrm.com/fXdWWh9" target="_blank"></a>
    </image-box>
    <image-box :src="require('../../assets/image/about/about_8.jpg?v=1')" :height="552"></image-box>
  </div>
</template>

<script>
  import ImageBox from "../../components/ImageBox/ImageBox";
  import {swiper, swiperItem} from '../../components/swiper/index'
  export default {
    name: "about",
    components: {
      "swiper": swiper,
      "swiper-item": swiperItem,
      "image-box":ImageBox
    },
    data() {
      return {
        swiperOption: {
          height:"auto",
          width:"100%",
          pageBtn: false,
          callback: () => {
          },
          pagination: {
            show: false,
            canClick:false
          },
          current: 0,
          itemsNum: 1,
          loop: false,
          loopTime: 5000,
          auto: false
        },
        // banner: [
        //   {
        //     img: require("../../assets/image/home/banner5.jpg?v=3"),
        //     url:"/article?id=62e3c4b0e4b0c9cb06b3a839",
        //     type:"route"
        //   }
        // ]
      }
    },
    created() {
    },
    methods: {
      bannerClick(item,i){
        if(item.type === "route"){
          this.$router.push({path: item.url})
        }else if(item.type === "blank"){
          window.open(item.url,"_blank")
        }else{

        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @media screen and (min-width: $minDeviceWidth_pc) {
    @import "about_pc";
  }

  @media screen and (max-width: $minDeviceWidth_pc) {
    @import "about_mobile";
  }
</style>
